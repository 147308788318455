import React, { Fragment, useEffect } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import Footer from "./components/Footer.react";
import PreFooter from "./components/PreFooter.react";
import Header from "./components/Header.react";
import CategoryMenu from "./components/CategoryMenu.react";
import Mosaic from "./components/Mosaic.react";
import Drawer from "./components/Drawer.react";
import ScrollToTop from "./../website/utils/ScrollToTop.react";

import DisplayAgendaBenefitList from "./components/DisplayAgendaBenefitList.react";
import DisplaySpotlightBenefitList from "./components/DisplaySpotlightBenefitList.react";

import Evento from "./pages/Evento.react";
import BenefitPage from "./pages/BenefitPage.react";
import BenefitSearchPage from "./pages/BenefitSearchPage.react";
import Sobre from "./pages/Sobre.react";
import Duvidas from "./pages/Duvidas.react";
import Parceiros from "./pages/Parceiros.react";
import Regulamento from "./pages/Regulamento.react";
import Contato from "./pages/Contato.react";
import CategoryPage from "./pages/category/CategoryPage.react";
import Missing from "./pages/Missing.react";
import Context from "@context";

import { ROUTERS_REDIRECT } from './utils/RouterRedirect';

const Home = ({ match }) => {
  return (
    <Fragment>
      <main id="home">
        <Mosaic />
        <CategoryMenu />
        <div id="pageContent">
          <div className="cnt">
            <DisplayAgendaBenefitList
              title={"Agenda"}
              description={"Confira os próximos eventos com descontos especiais do Clube!"}
            />

            <DisplaySpotlightBenefitList
              title={"Destaques"}
              description={"Parceiros em Destaque"}
            />
          </div>
        </div>
      </main>
    </Fragment>
  );
};

const AgendaPage = ({ match }) => {
  return (
    <Fragment>
      <main id="home">
        <CategoryMenu />
        <div id="pageContent">
          <div className="cnt">
            <DisplayAgendaBenefitList
              title={"Agenda"}
              description={"Confira os próximos eventos com descontos especiais do Clube!"}
              seeAll={true}
            />
          </div>
        </div>
      </main>
    </Fragment>
  );
};

const DestaquesPage = ({ match }) => {
  return (
    <Fragment>
      <main id="home">
        <CategoryMenu />
        <div id="pageContent">
          <div className="cnt">
            <DisplaySpotlightBenefitList
              title={"Destaques"}
              description={"Parceiros em Destaque"}
              seeAll={true}
            />
          </div>
        </div>
      </main>
    </Fragment>
  );
};
const GoToHome = () => {
  return <Redirect to="/" />;
};

const Benefit = ({ match }) => {
  return (
    <Fragment>
      <main id="other">
        <CategoryMenu />
        <BenefitPage />
      </main>
    </Fragment>
  );
};

const Search = ({ match }) => {
  return (
    <Fragment>
      <main id="search">
        <CategoryMenu />
        <div className="pageContent">
          <BenefitSearchPage />
        </div>
      </main>
    </Fragment>
  );
};

const WebView = ({ match }) => {
  const { action: ctxAction, state: ctxState } = Context.useState();
  const [content, setContent] = React.useState([]);
  React.useEffect(() => {
    ctxAction("content")
      .find({}, {})
      .then(res => {
        if (res.result && res.result.values) {
          const contentCollection = res.result.values.reduce((acc, content) => {
            return {
              ...acc,
              [content.identifier]: content.body
            };
          }, {});
          setContent(contentCollection);
        }
      });
  }, []);

  return (
    <Fragment>
      <ScrollToTop>
        <Helmet>
          <html lang="pt-br" />
          <meta http-equiv="X-UA-Compatible" content="ie=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <title>{process.env.head_title}</title>
          {/* Libraries needed for chat support */}

          {/* Genesys backend support */}
          <script src="https://sdk-cdn.mypurecloud.com/javascript-guest/5.1.0/purecloud-guest-chat-client.min.js"></script>

          {/* JQuery integratino */}
          <script
            src="https://code.jquery.com/jquery-3.4.1.min.js"
            integrity="sha256-CSXorXvZcTkaix6Yvo6HppcZGetbYMGWSFlBw8HfCJo="
            crossorigin="anonymous"
          ></script>

          {/* bootstrap */}
          <link
            rel="stylesheet"
            href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css"
            integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO"
            crossorigin="anonymous"
          ></link>
          <script
            src="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/js/bootstrap.min.js"
            integrity="sha384-ChfqqxuZUCnJSK3+MXmPNIyE6ZbWh2IMqE241rYiqJxyMiZ6OW/JmZQ5stwEULTy"
            crossorigin="anonymous"
          ></script>

          {/* Font Awesome */}
          <script defer src="https://kit.fontawesome.com/6b338f1620.js"></script>

          {/* Perfect Scorllbar */}
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/jquery.perfect-scrollbar/1.5.0/css/perfect-scrollbar.min.css"
          ></link>
          <script
            type="text/javascript"
            src="https://cdnjs.cloudflare.com/ajax/libs/jquery.perfect-scrollbar/1.5.0/perfect-scrollbar.min.js"
          ></script>

          {/* moment */}
          <script
            src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.24.0/moment-with-locales.min.js"
            charset="UTF-8"
          ></script>
          <script
            src="https://cdnjs.cloudflare.com/ajax/libs/moment-timezone/0.5.27/moment-timezone-with-data.min.js"
            charset="UTF-8"
          ></script>

          {/* select2 */}
          <link
            href="https://cdn.jsdelivr.net/npm/select2@4.0.12/dist/css/select2.min.css"
            rel="stylesheet"
          />
          <script src="https://cdn.jsdelivr.net/npm/select2@4.0.12/dist/js/select2.min.js"></script>

          <script src="https://nsc.invenit.cloud/chat/assets/plugins/FloatingLabel/floatingLabel.min.js"></script>

          <script>
            var out_of_schedule_msg = "A NSC agradece o seu contato! Nosso horário de atendimento é
            das 08h às 16h de segunda à sexta-feira. Por favor, entre em contato novamente."; var
            agent_leave_chat_msg = "Sessão de Chat Encerrada"; var agent_enter_chat_msg = "O agente
            entrou no chat"; var agent_header_msg = "É um prazer te ajudar!"; var
            agent_header_waiting_msg = "Aguardando o agente entrar na chat"; var start_schedule =
            "00:00:00"; var end_schedule = "23:59:59"; var site_enable_1 = "clubensc.com.br";
          </script>
          <link
            rel="stylesheet"
            href="https://nsc.invenit.cloud/chat/assets/css/chat.min.css"
          ></link>
          <script
            src="https://nsc.invenit.cloud/chat/assets/javascript/chat.min.js"
            type="application/javascript"
          ></script>

          <link rel="stylesheet" href={`${process.env.domain}assets/website/css/main.css`} />
          <link
            href="https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900"
            rel="stylesheet"
          ></link>
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`${process.env.domain}assets/website/favicon/apple-touch-icon.png`}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={`${process.env.domain}assets/website/favicon/favicon-32x32.png`}
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={`${process.env.domain}assets/website/favicon/favicon-16x16.png`}
          />
          <link
            rel="manifest"
            href={`${process.env.domain}assets/website/favicon/site.webmanifest`}
          ></link>
          <script
            type="text/javascript"
            async
            src="https://platform.twitter.com/widgets.js"
          ></script>
          <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-W26HPBN');`}</script>

          {/* One Signal*/}
          <script src="https://cdn.onesignal.com/sdks/OneSignalSDK.js" async="" />
          <script>
            {`var OneSignal = window.OneSignal || [];
              OneSignal.push(function() {
                OneSignal.init({
                  appId: "f1f3a1ed-132a-41f7-b865-c79c7fe4b276",
                });
              });`}
          </script>
          {/* inline style elements */}
          <style type="text/css">{`
              .container {
                background: none !important;
              }
              body {
                  background: none !important;
              }
              @media only screen and (max-width: 375px) {
                  .chat_start_screen {
                      width: 290px;
                  }
                  .chat-header {
                      padding-bottom: 8px !important;
                  }
                  .chat-about {
                      width: 150px;
                  }
                  .chat-with {
                      font-size: 12px !important;
                  }
                  .chat-host-status {
                      font-size: 15px;
                  }
              }
          `}</style>
        </Helmet>
        <Header />
        <Switch>
          {
            ROUTERS_REDIRECT.map((router, index) =>
              <Route key={index.toString()} exact path={router.path} component={() =>
                <Redirect to={router.redirect} />} />)
          }
          <Route key={"404"} exact path={"/404"} component={Missing} />
          <Route key={"sobre"} exact path={`/sobre`} render={routeProps => <Sobre {...routeProps} contentCollection={content} />} />
          <Route key={"parceiros"} exact path={`/parceiros`} component={Parceiros} />
          <Route key={"eventos"} exact path={`/eventos`} component={Evento} />
          <Route key={"duvidas"} exact path={`/duvidas`} component={Duvidas} />
          <Route key={"duvidasFrequentes"} exact path={`/duvidas-frequentes`} component={Duvidas} />
          <Route key={"contato"} exact path={`/contato`} component={Contato} />
          <Route key={"regulamento"} exact path={`/regulamento`} component={Regulamento} />
          <Route key={"beneficio"} exact path={`/beneficio/:id`} component={Benefit} />
          <Route key={"search"} exact path={"/search/"} component={() => <Redirect to="/" />} />
          <Route key={"search"} exact path={`/search/:text`} component={Search} />
          <Route key={"agenda"} exact path={`/agenda`} component={AgendaPage} />
          <Route key={"destaques"} exact path={`/destaques`} component={DestaquesPage} />
          <Route key={"category-sub"} exact path={`/:categoryName/:subCategoryName`} component={CategoryPage} />
          <Route key={"category"} exact path={`/:categoryName`} component={CategoryPage} />
          <Route key={"home"} exact path={`/`} component={Home} />
          <Route component={Missing} />
        </Switch>
        <PreFooter contentCollection={content} />
        <Footer />
        <Drawer />
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-W26HPBN"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
      </ScrollToTop>
    </Fragment>
  );
};

export default withRouter(WebView);
