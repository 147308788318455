import { schema, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form"

const schm = schema("branch", {
  _id: {
    type: types.string.type,
    field: fields.textfield.type
  },
  name: {
    type: types.string.type,
    field: fields.textfield.type,
    required: true
  },
  type: {
    type: types.string.type,
    field: fields.select.type,
    required: { contains: true }
  },
  phone: {
    type: types.string.type,
    field: fields.textfield.type
  },
  site: {
    type: types.string.type,
    field: fields.textfield.type
  },
  stage: {
    type: types.string.type,
    field: fields.select.type,
    required: { contains: true }
  },
  status: {
    type: types.boolean.type,
    field: fields.checkbox.type,
    required: true
  }
});

export { schm as schema };

