const LocalDeTrocaLayout = {
  labels: [],
  datasets: [
    {
      label: "Local de troca",
      backgroundColor: "#622D8F",
      borderColor: "#622D8F",
      borderWidth: 1,
      data: []
    }
  ]
};

export default LocalDeTrocaLayout;