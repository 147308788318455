const DiasDaSemanaLayout = {
  labels: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
  datasets: [
    {
      label: "Dias da semana",
      backgroundColor: "#622D8F",
      borderColor: "#622D8F",
      borderWidth: 1,
      data: []
    }
  ]
};

export default DiasDaSemanaLayout;
