import React, { Fragment } from "react";

import Access from "@cthulhi/comp-accs";

import permissions from "@perm";

const AssinantesGroup = ({ path, options = {} }) => {
  return (
    <Access.RestrictComponent
      onDeny={() => null}
      onLoading={() => null}
      onAccess={[
        permissions.subscription.view,
        permissions.subscription.viewAny,
        permissions.subscription.edit,
        permissions.subscription.editAny,
        permissions.redemption.viewAny,
        permissions.userVip.viewAny
      ]}
    >
      <li>
        <span className="menu-title uk-margin-top">Assinantes</span>
      </li>
      {/*<li>
         <li>
          <Access.RestrictNavLink { ...options }
            onAccess={[
              permissions.partner.view,
              permissions.partner.viewAny,
              permissions.partner.edit,
              permissions.partner.editAny
            ]}
            to={`${path}/partner`}
            onDeny={() => null}
            className="menu-link"
            activeClassName="active"
          >
            <i className="icon icon-bullet" aria-hidden="true"></i>
            <span>Listar Sócio Beneficiados</span>
          </Access.RestrictNavLink>
        </li>
        <Access.RestrictNavLink
          {...options}
          onAccess={[
            permissions.subscription.view,
            permissions.subscription.viewAny,
            permissions.subscription.edit,
            permissions.subscription.editAny
          ]}
          to={`${path}/subscriber`}
          onDeny={() => null}
          className="menu-link"
          activeClassName="active"
        >
          <i className="icon icon-bullet" aria-hidden="true"></i>
          <span>Listar assinantes</span>
        </Access.RestrictNavLink>
      </li>
       */}
      <li>
        <Access.RestrictNavLink
          {...options}
          onAccess={[permissions.redemption.viewAny]}
          onDeny={() => null}
          to={`${path}/redemption`}
          exact
          className="menu-link"
          activeClassName="active"
        >
          <i className="icon icon-bullet" aria-hidden="true"></i>
          <span>Listar resgates</span>
        </Access.RestrictNavLink>
      </li>
      <li>
        <Access.RestrictNavLink
          {...options}
          onAccess={[permissions.userVip.viewAny]}
          onDeny={() => null}
          to={`${path}/user-vip`}
          className="menu-link"
          activeClassName="active"
        >
          <i className="icon icon-bullet" aria-hidden="true"></i>
          <span>Listar beneficiados</span>
        </Access.RestrictNavLink>
        <Access.RestrictNavLink
          {...options}
          onAccess={[permissions.userVip.viewAny]}
          onDeny={() => null}
          to={`${path}/user-vip/create`}
          className="menu-link"
          activeClassName="active"
          data-uk-tooltip="title: Adicionar Beneficiado; pos: right"
        >
          <i className="icon icon-plus-circle" aria-hidden="true"></i>
        </Access.RestrictNavLink>
      </li>
    </Access.RestrictComponent>
  );
};

export default AssinantesGroup;
