import websiteConstant from "../constants/website.constant";
import getCategorySpecificParams from "@html/views/website/utils/getCategorySpecificParams";

export default (dispatch, state) => {
  const toggleDrawerMenu = () => {
    dispatch({
      type: websiteConstant.UPDATE_STATE,
      data: {
        drawerOpen: !state.drawerOpen
      }
    });
  };

  const closeDrawerMenu = () => {
    dispatch({
      type: websiteConstant.UPDATE_STATE,
      data: {
        drawerOpen: false
      }
    });
  };

  const toggleItemDrawer = _id => {
    dispatch({
      type: websiteConstant.UPDATE_MENU_ITEM_STATE,
      data: { _id }
    });
  };

  const sliderNext = () => {
    if (state.activeSlider === state.sliders.length - 1) {
      dispatch({
        type: websiteConstant.UPDATE_STATE,
        data: {
          activeSlider: (state.activeSlider = 0)
        }
      });
    } else {
      dispatch({
        type: websiteConstant.UPDATE_STATE,
        data: {
          activeSlider: state.activeSlider + 1
        }
      });
    }
  };

  const sliderPrev = () => {
    if (state.activeSlider === 0) {
      dispatch({
        type: websiteConstant.UPDATE_STATE,
        data: {
          activeSlider: (state.activeSlider = state.sliders.length - 1)
        }
      });
    } else {
      dispatch({
        type: websiteConstant.UPDATE_STATE,
        data: {
          activeSlider: state.activeSlider - 1
        }
      });
    }
  };

  const slideToDot = index => {
    dispatch({
      type: websiteConstant.UPDATE_STATE,
      data: {
        activeSlider: index
      }
    });
  };

  const toggleDropdown = () => {
    dispatch({
      type: websiteConstant.UPDATE_STATE,
      data: {
        toggleDropdown: !state.toggleDropdown
      }
    });
  };

  const toggleAccordion = _id => {
    dispatch({
      type: websiteConstant.UPDATE_ACCORDION,
      data: { _id }
    });
  };

  const sendMessage = (values, authorization) => {
    const args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        to: process.env.contactEmailTo,
        from: process.env.contactEmailFrom,
        subject: "Novo contato pelo site",
        templateId: process.env.contactEmailTemplate,
        substitutionData: {
          ...values,
          domainHtml: process.env.domain,
        }
      }),
      credentials: "same-origin"
    };

    return fetch(process.env.domainSenderEmail, args)
      .then(res => res.json())
      .then(res => {
        return res;
      })
      .catch(error => {
        throw error;
      });
  };

  const getBenefitList = (filters, options) => {
    const args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        filters: { ...filters },
        options: { ...options }
      }),
      credentials: "same-origin"
    };

    return fetch(process.env.domainBenefitGetList, args)
      .then(res => res.json())
      .then(res => {
        return res.result;
      })
      .catch(error => {
        throw error;
      });
  };

  const getEventList = (filters, options) => {
    const args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        filters: { ...filters },
        options: { ...options }
      }),
      credentials: "same-origin"
    };

    return fetch(process.env.domainBenefitGetEventList, args)
      .then(res => res.json())
      .then(res => {
        if (!res.result) {
          throw {};
        }

        return res.result;
      })
      .catch(error => {
        throw error;
      });
  };

  const getBenefitInfo = (filters, options) => {
    var args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        filters: { ...filters },
        options: { ...options }
      }),
      credentials: "same-origin"
    };
    return fetch(process.env.domainBenefitGetItem, args)
      .then(res => res.json())
      .then(res => {
        return res;
      })
      .catch(error => {
        throw error;
      });
  };

  const fetchAllCategories = (filters, options, loader) => {
    let args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ ...filters, options }),
      credentials: "same-origin"
    };

    if (loader) loader.start();

    return fetch(process.env.domainCategoryGetAll, args)
      .then(res => res.json())
      .then(res => {
        const categorias = res.result.values;
        const novaListaDeCategoria = categorias
          .filter(categoria => categoria.parentId == null)
          .map(categoria => {
            return {
              _id: categoria._id,
              open: false,
              titulo: categoria.name,
              parentId: categoria.parentId,
              showMenu: categoria.showMenu,
              showApp: categoria.showApp,
              status: categoria.status,
              weight: categoria.weight,
              weightByDate: categoria.weightByDate,
              link: categoria.name.toLowerCase(),
              iconWebsiteUrl: categoria.iconWebsiteUrl,
              subCategorias: categorias
                .filter(subCategoria => categoria._id === subCategoria.parentId)
                .map(subCategoria => ({
                  _id: subCategoria._id,
                  link: subCategoria.name.toLowerCase(),
                  titulo: subCategoria.name
                })),
              ...getCategorySpecificParams(categoria.name)
            };
          });

        dispatch({
          type: websiteConstant.UPDATE_STATE,
          data: {
            categorias: novaListaDeCategoria
          }
        });

        if (loader) loader.finish();

        return res;
      })
      .catch(error => {
        if (loader) loader.finish();
        dispatch({
          type: websiteConstant.UPDATE_STATE,
          data: {
            categorias: []
          }
        });
        throw error;
      });
  };

  const getBannerList = (filters, options, authorization) => {
    let args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authorization
      },
      body: JSON.stringify({ ...filters, options }),
      credentials: "same-origin"
    };

    return fetch(process.env.domainBannerGetList, args)
      .then(res => res.json())
      .then(res => {
        return res.result.values;
      })
      .catch(error => {
        throw error;
      });
  };

  const filterFaqList = (filters, options, loader) => {
    let args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ ...filters, options }),
      credentials: "same-origin"
    };

    if (loader) loader.start();

    return fetch(process.env.domainFaqFind, args)
      .then(res => res.json())
      .then(res => {
        const faqList = res.result.values;

        dispatch({
          type: websiteConstant.UPDATE_STATE,
          data: {
            newList: faqList
          }
        });

        if (loader) loader.finish();

        return res;
      })
      .catch(error => {
        if (loader) loader.finish();
        dispatch({
          type: websiteConstant.UPDATE_STATE,
          data: {
            newList: []
          }
        });
        throw error;
      });
  };

  const getTermList = (filters, options) => {
    let args = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        filters,
        options,
      })
    };
    
    return fetch(process.env.domainBenefitSearchByTermWithBranches, args)
      .then(res => res.json())
      .then(res => {
        if (!res.result) {
          throw res;
        }

        const newBenefitList = (res.result.values || []);

        return res.result;
      })
      .catch(error => {
        throw error;
      });
  };

  return {
    toggleDrawerMenu,
    closeDrawerMenu,
    toggleItemDrawer,
    sliderNext,
    sliderPrev,
    slideToDot,
    toggleDropdown,
    toggleAccordion,
    sendMessage,
    getBenefitList,
    fetchAllCategories,
    getBenefitInfo,
    getBannerList,
    getEventList,
    filterFaqList,
    getTermList
  };
};
