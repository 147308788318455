import React, { Component, Fragment, useEffect, useRef } from "react";

import Chart from "chart.js";

import Context from "@html-context";

const ChartComponent = props => {
  const chartRef = useRef(null);

  useEffect(() => {
    new Chart(chartRef.current.getContext("2d"), {
      type: props.type,
      data: props.dataLayout,
      options: props.options ? props.options : []
    });
  }, [props.dataLayout]);

  return (
    <Fragment>
      <div className="graphic-container">
        <canvas id={props.id} ref={chartRef} />
      </div>
    </Fragment>
  );
};

export default ChartComponent;
