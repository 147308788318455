import { schema, types } from "@cthulhi/pack-schm";
import { types as fields } from "@cthulhi/comp-form";

const schm = schema("branch", {
  _id: {
    type: types.string.type,
    field: fields.textfield.type
  },
  name: {
    type: types.string.type,
    field: fields.textfield.type,
    required: true
  },
  type: {
    type: types.string.type,
    field: fields.select.type,
    required: { contains: true }
  },
  zipcode: {
    type: types.string.type,
    field: fields.textfield.type,
    required: true
  },
  district: {
    type: types.string.type,
    field: fields.textfield.type,
    required: true
  },
  address: {
    type: types.string.type,
    field: fields.textfield.type,
    required: true
  },
  number: {
    type: types.integer.type,
    field: fields.textfield.type
  },
  complement: {
    type: types.string.type,
    field: fields.textfield.type
  },
  state: {
    type: types.integer.type,
    field: fields.select.type,
    required: true
  },
  city: {
    type: types.string.type,
    field: fields.select.type,
    required: true
  },
  phone: {
    type: types.string.type,
    field: fields.textfield.type
  },
  site: {
    type: types.string.type,
    field: fields.textfield.type
  },
  stage: {
    type: types.string.type,
    field: fields.select.type,
    required: { contains: true }
  },
  status: {
    type: types.boolean.type,
    field: fields.checkbox.type,
    required: true
  },
  googlemaps: {
    type: types.object.type,
    field: fields.googlemaps.type,
    required: true
  },
  latitude: {
    type: types.string.type,
    field: fields.textfield.type,
    required: true
  },
  longitude: {
    type: types.string.type,
    field: fields.textfield.type,
    required: true
  }
});

export { schm as schema };
