const PercentOptions = {
  legend: {
    display: false
  },
  tooltips: {
    callbacks: {
      label: function(tooltipItem, data) {
        var label = data.datasets[tooltipItem.datasetIndex].label || "";

        if (label) {
          label += ": ";
        }
        label += tooltipItem.xLabel;
        return label + "%";
      }
    }
  },
  scales: {
    xAxes: [
      {
        ticks: {
          stepSize: 25,
          beginAtZero: true,
          min: 0,
          max: 100,
          callback: function(value) {
            return value + "%";
          }
        }
      }
    ]
  }
};

export default PercentOptions;
