export default (ctxAction, token) => {
  const toPercentage = total => number => {
    return ((number / total) * 100).toFixed(2);
  };

  return {
    ageReport: (ageRanges, branches, dateFilter) => {
      const filters = {
        filters: {
          ...dateFilter
        }
      };

      const options = {
        options: { sort: { _id: -1 } }
      };

      if (branches.length) {
        filters.filters = {
          ...filters.filters,
          branchId: {
            $in: branches
          }
        };
      }

      return ctxAction("redemption")
        .reports.age({ ageRanges }, filters, options, token)
        .then(res => {
          if (res.result && res.result.age && res.result.age[0]) {
            const ranges = res.result.age[0];

            const format = toPercentage(ranges.total);

            const ageRangesPercentage = [];

            for (const { min, max } of ageRanges) {
              const key = max ? `${min}-${max}` : `${min}+`;
              ageRangesPercentage.push(format(ranges[key]));
            }

            return ageRangesPercentage;
          } else {
            return ageRanges.map(_ => 0);
          }
        })
        .catch(e => console.error("ERROR AGE REPORT", e));
    },

    cityReport: (branches, dateFilter) => {
      const filters = {
        filters: {
          ...dateFilter
        }
      };

      if (branches.length) {
        filters.filters = {
          ...filters.filters,
          branchId: {
            $in: branches
          }
        };
      }

      const options = {
        options: { sort: { count: 1 } }
      };

      return ctxAction("redemption")
        .reports.city(filters, options, token)
        .then(res => {
          if (res.result && res.result.city) {
            const labels = [];
            const data = [];
            for (const city of res.result.city) {
              labels.push(city._id);
              data.push(city.count);
            }
            return { labels, data };
          }
          return { labels: [], data: [] };
        })
        .catch(e => console.error("ERROR CITY REPORT", e));
    },

    periodReport: (branches, dateFilter) => {
      const filters = {
        filters: {
          ...dateFilter
        }
      };

      if (branches.length) {
        filters.filters = {
          ...filters.filters,
          branchId: {
            $in: branches
          }
        };
      }

      const options = {
        options: { sort: { count: 1 } }
      };

      return ctxAction("redemption")
        .reports.period(filters, options, token)
        .then(res => {
          if (res.result && res.result.period && res.result.period[0]) {
            const periods = res.result.period[0];
            // there is also Madrugada/periods.dawn
            const labels = ["Manhã", "Tarde", "Noite"];
            const data = [periods.morning, periods.afternoon, periods.night];

            return { labels, data };
          }
          return { labels: [], data: [] };
        })
        .catch(e => console.error("ERROR PERIOD REPORT", e));
    },

    recurrentReport: (branches, dateFilter) => {
      const filters = {
        filters: {
          ...dateFilter
        }
      };

      if (branches.length) {
        filters.filters = {
          ...filters.filters,
          branchId: {
            $in: branches
          }
        };
      }

      const options = {
        options: { sort: { count: 1 } }
      };

      return ctxAction("redemption")
        .reports.recurrent(filters, options, token)
        .then(res => {
          if (res.result && res.result.recurrent && res.result.recurrent[0]) {
            const { systemNew, branchRecurrent, total } = res.result.recurrent[0];
            return { systemNew, branchRecurrent, total };
          }
          return { systemNew: 0, branchRecurrent: 0, total: 0 };
        })
        .catch(e => console.error("ERROR RECURRENT REPORT", e));
    },

    weekdayReport: (branches, dateFilter) => {
      const filters = {
        filters: {
          ...dateFilter
        }
      };

      if (branches.length) {
        filters.filters = {
          ...filters.filters,
          branchId: {
            $in: branches
          }
        };
      }

      const options = {};

      return ctxAction("redemption")
        .reports.weekday(filters, options, token)
        .then(res => {
          //const labels = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"]
          const reportCountByDay = [0, 0, 0, 0, 0, 0, 0];

          if (res && res.result && res.result.weekday) {
            const rawWeekdaysCount = res.result.weekday;
            const total = rawWeekdaysCount.reduce((sum, day) => {
              return sum + day.count;
            }, 0);

            const format = toPercentage(total);

            const transformedIntoArray = reportCountByDay.map((_, index) => {
              // _id starts at 1 so _id == index + 1
              const weekday = rawWeekdaysCount.find(weekday => weekday._id == index + 1);
              if (weekday) {
                return format(weekday.count);
              }
              return 0;
            });

            return transformedIntoArray;
          }

          return reportCountByDay;
        })
        .catch(e => console.error("ERROR WEEKDAY REPORT", e));
    },

    districtReport: (branches, dateFilter) => {
      const filters = {
        filters: {
          ...dateFilter
        }
      };

      if (branches.length) {
        filters.filters = {
          ...filters.filters,
          branchId: {
            $in: branches
          }
        };
      }

      const options = {
        options: {
          sort: { count: 1 }
        }
      };

      return ctxAction("redemption")
        .reports.district(filters, options, token)
        .then(res => {
          const labels = [];
          const data = [];
          if (res.result && res.result.district && res.result.district) {
            const totalByDistrict = res.result.district;

            for (const district of totalByDistrict) {
              labels.push(district._id);
              data.push(district.count);
            }
          }

          return { labels, data };
        })
        .catch(e => console.error("ERROR DISTRICT REPORT", e));
    },

    voucherStatus: (branches, dateFilter) => {
      const filters = {
        filters: {
          ...dateFilter
        },
        aggregates: [
          {
            $group: {
              _id: "$status",
              count: { $sum: 1 }
            }
          },
          {
            $sort: { count: -1 }
          }
        ]
      };

      if (branches.length) {
        filters.filters = {
          ...filters.filters,
          branchId: {
            $in: branches
          }
        };
      }

      const options = {};

      return ctxAction("voucher")
        .find(filters, options, token)
        .then(res => {
          const statusCount = {
            pending: 0,
            tovalidate: 0,
            used: 0,
            total: 0
          };

          if (res.result && res.result.values && res.result.values[0]) {
            const values = res.result.values;

            for (const status of values) {
              if (statusCount[status._id] === 0) {
                statusCount[status._id] = status.count;
                statusCount.total += status.count;
              }
            }

            return statusCount;
          }

          return statusCount;
        })
        .catch(e => console.log("ERROR VOUCHERSTATUS", e));
    }
  };
};
