const PeriodoLayout = {
  labels: [],
  datasets: [
    {
      label: "Período",
      backgroundColor: "#622D8F",
      borderColor: "#622D8F",
      borderWidth: 1,
      data: []
    }
  ]
};

export default PeriodoLayout;