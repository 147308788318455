import React, { useCallback } from "react";
import ReactLoader from "./loader/Loader.react";
import Styled, { css, keyframes } from "styled-components";

import Context from "@context";

const fadeOut = keyframes`
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
`;

const animationFadeOut = css`
  opacity: 0;
  animation: ${fadeOut} 0.5s linear;
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
`;

const animationFadeIn = css`
  opacity: 1;
  animation: ${fadeIn} 0.5s linear;
`;

const LoaderContainer = Styled.div`
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 998;
  ${props => (props.isFadeOut ? animationFadeOut : null)}
`;

const FadeInDiv = Styled.div`
  ${animationFadeIn}
`;

const Loader = ({ isFadeOut, ...props }) => {
  return (
    <LoaderContainer isFadeOut={isFadeOut} className="uk-overlay-default uk-position-cover">
      <FadeInDiv>
        <ReactLoader {...props} className="uk-position-fixed uk-position-center" />
      </FadeInDiv>
    </LoaderContainer>
  );
};

const LoaderCustomComponent = ({ from, height = "75", width = "75" }) => {
  const { state, action } = Context.useState();

  return (
    <Loader
      type="doubleRing"
      height={height}
      width={width}
      isFadeOut={
        typeof state.loader.list[from] == "undefined" || state.loader.list[from].length == 0
      }
      color1="#1a65bb"
      color2="#03aeff"
    />
  );
};

export default LoaderCustomComponent;
