import React, { Fragment, useState, useEffect } from "react";
import Papa from "papaparse";
import Context from "@context";
import Access from "@cthulhi/comp-accs";

import Overlay from "../../../../utils/overlay/Overlay.react";
import ModalConfirmRemoval from "../../../../wrappers/ModalConfirmRemoval.react";
import Notification from "@cthulhi/comp-noty";
import NotificationComponent from "../../../../utils/Notification.react";
import LoaderComponent from "../../../../utils/LoaderCustomComponent.react";
import { FieldLabelWrapper } from "../../../../wrappers/FormWrappers.react";

const PreGeneratedModal = ({ visible, onClose, benefitId }) => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction, state: ctxtState } = Context.useState();
  const { state: ntfState, action: ntfAction } = Notification.useState();
  const [modal, setModal] = useState({ show: false, Content: () => null });
  const [codeList, setCodeList] = useState([]);
  const [listLimit, setListLimit] = useState(0);
  const listLimitValue = 1000;
  const [codeFilterValue, setCodeFilterValue] = useState("");
  const [idFilterValue, setIdFilterValue] = useState("");

  const token = `Bearer ${accState.access.session.context.token}`

  const onChangeCodeFilter = (event) => {
    setCodeFilterValue(event.target.value);
  }

  const onChangeIdFilter = (event) => {
    setIdFilterValue(event.target.value);
  }

  //fields and values
  //#region 
  const filterCodeFields = {
    filterByCode: {
      attrs: {
        type: "string",
        field: "textfield"
      },
      events: {
        onChange: onChangeCodeFilter
      },
      functs: {}
    },
  };

  const filterCodeValues = {
    filterByCode: {
      className: "uk-input",
      filter: "text",
      placeholder: "Filtrar por código",
      label: {
        text: "Filtrar por código",
        className: "uk-form-label"
      },
      value: codeFilterValue,
      rawValue: codeFilterValue,
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
  };

  const filterByStatusOrIdFields = {
    filterByStatusOrId: {
      attrs: {
        type: "string",
        field: "textfield"
      },
      events: {
        onChange: onChangeIdFilter
      },
      functs: {}
    },
  };
  
  const filterByStatusOrIdValues = {
    filterByStatusOrId: {
      className: "uk-input",
      filter: "text",
      placeholder: "Filtrar por ID do Voucher",
      label: {
        text: "Filtrar por ID do Voucher",
        className: "uk-form-label"
      },
      value: idFilterValue,
      rawValue: idFilterValue,
      wrapper: {
        className: "field-wrapper uk-width-1-3@m"
      }
    },
  };

  //#endregion

  const filterVoucher = (code) => {
    //when less than 3 characters for the filters, ignore and show all codes
    //if any filter has 3 chars or more, start evaluating either one 
    if(codeFilterValue.length < 3 && idFilterValue.length < 3) return true;

    const voucherCode = codeFilterValue.length < 3 ? null : code.code;
    const voucherId = idFilterValue.length < 3 ? null : code.voucherId;
    const foundCode = evaluateCodeFilter(voucherCode);
    const foundID = evaluateIdFilter(voucherId);
    return foundCode || foundID;
  }

  const evaluateCodeFilter = (voucherCode) => {
    if(voucherCode === null) return false
    return voucherCode.toUpperCase().includes(codeFilterValue.toUpperCase())
  }

  const evaluateIdFilter = (voucherId) => {
    if(voucherId === null) return false
    return voucherId.toUpperCase().includes(idFilterValue.toUpperCase()) === true;
  }

  const renderTopFilters = () => {

    return (
      <div className="uk-flex uk-flex-between uk-margin-medium-bottom">
        <FieldLabelWrapper
          key={"filterByCode"}
          name={"filterByCode"}
          fields={filterCodeFields}
          values={filterCodeValues}
        />
        <FieldLabelWrapper
          key={"filterByStatusOrId"}
          name={"filterByStatusOrId"}
          fields={filterByStatusOrIdFields}
          values={filterByStatusOrIdValues}
        />
    </div>
    )
  }

  const onLoadMore = () =>{
    setListLimit(listLimit + listLimitValue)
  }

  const renderLoadMoreButton = () => {
    if(codeList.length < listLimitValue) return null;

    return (
      <button className="uk-button uk-button-default" type="button" onClick={onLoadMore}>Carregar Mais</button> 
    )
  }

  const onFileSelected = e => {
    Papa.parse(e.currentTarget.files[0], {
      complete: content => {
        const reducedData = content.data
          .filter(list => list[0])
          .map(code => ({
            code: code[0],
            voucherId: null,
          }));
        setCodeList([...codeList, ...reducedData])
        const newTotalLength = codeList.length+reducedData.length;
        setListLimit(newTotalLength < listLimitValue ? newTotalLength : listLimitValue);
      }
    })
  }

  const saveNewCodeList = (codes) => {
    return ctxAction("voucherCode").createBulk(
      { codeList: codes },
      token,
      {
        start: () => ctxAction("loader").addLoading("find", "preGeneratedModal"),
        finish: () => ctxAction("loader").removeLoading("find", "preGeneratedModal")
      }
    )
  }

  const onSave = (e) => {
    const newCodeList = codeList
      .filter(code => !code._id)
      .map(code => {
        return {
          ...code,
          benefitId
        }
      })

    if (newCodeList.length == 0) return onClose();

    saveNewCodeList(newCodeList).then((res) => {
      if (res.code == 11000) {
        return ntfAction("notification").add(
          1,
          "preGeneratedModal",
          "danger",
          "Não foi possível cadastrar a lista de códigos. Existem códigos duplicados ou que já foram salvos anteriormente.",
          undefined,
          process.env.notificationTimeOut
        );
      }
      return onClose();
    }).catch(() => {
      return ntfAction("notification").add(
        1,
        "preGeneratedModal",
        "danger",
        "Não foi possível cadastrar a lista de códigos.",
        undefined,
        process.env.notificationTimeOut
      );
    })

  }

  const onDelete = (code) => {
    setModal({
      show: true,
      Content: props => {
        return (
          <ModalConfirmRemoval
            onConfirm={() => {
              if (code.voucherId) {
                return ntfAction("notification").add(
                  1,
                  "preGeneratedModal",
                  "danger",
                  "Esse código não pode ser removido pois já foi usado na criação de um voucher.",
                  undefined,
                  process.env.notificationTimeOut
                );
              }
              if (code._id) {
                ctxAction("voucherCode").remove({ _id: code._id }, token)
                  .then(() => getVoucherCodeList(benefitId))
              } else {
                setCodeList([...codeList.filter(c => c.code !== code.code)])
              }
            }}
            onClose={() => setModal({ show: false, Content: () => null })}
          />
        );
      }
    })

  }

  const getPreGeneratedCodes = (benefitId) => {
    return ctxAction("voucherCode").find(
      { filters: { benefitId } },
      { sort: { code: 1 } },
      token,
      {
        start: () => ctxAction("loader").addLoading("find", "preGeneratedModal"),
        finish: () => ctxAction("loader").removeLoading("find", "preGeneratedModal")
      }
    ).
      then(res => {
        if (res.result && res.result.values) {
          return res.result.values
        }
        return []
      })
  };

  const getVoucherCodeList = (benefitId) => {
    if (benefitId) {
      getPreGeneratedCodes(benefitId)
        .then((res) => {
          if(res){
            setCodeList(res)
            setListLimit(res.length < listLimitValue ? res.length : listLimitValue); 
          }
        })
        .catch(() => setCodeList([]))
    }
  }

  React.useEffect(() => {
    getVoucherCodeList(benefitId)
  }, [benefitId]);

  if (!visible) return null;

  return (
    <Overlay backgroundColor="#000000" backgroundOpacity="0.5" onClick={onClose}>
      <div className="uk-modal-dialog" style={{ opacity: 1, transform: "translateY(0)", width: "700px" }}>
        <div className="uk-modal-header">
          <h2 className="uk-modal-title">Pré-Cadastro de códigos</h2>
        </div>
        <LoaderComponent from="preGeneratedModal" />
        <div className="uk-modal-body">
          <Notification.NotificationWrapper group="preGeneratedModal" component={NotificationComponent} />
          {renderTopFilters()}
          <div className="field-wrapper uk-width-1-1" style={{ overflowY: "scroll", maxHeight: "500px" }}>
            {codeList && codeList.length ? (
              <>
                <table className="uk-table uk-table-small uk-table-striped">
                  <thead>
                    <th>Código</th>
                    <th>ID voucher</th>
                    <th>Ações</th>
                  </thead>
                  <tbody>

                    {codeList.filter((code)=> filterVoucher(code))
                      .slice(0,listLimit)
                      .map((code) => (
                        <tr key={code._id ? code._id : code.code}>
                          <th>{code.code}</th>
                          <th>{code.voucherId || "Código não usado"}</th>
                          <th>
                            <button
                              className="uk-icon-button uk-button-danger uk-margin-small-left"
                              onClick={() => onDelete(code)}
                            >
                              <i className="icon icon-delete" aria-hidden="true"></i>
                            </button>
                          </th>
                        </tr>
                      ))}

                  </tbody>
                </table>
              </>
            ) : <div>Nenhum código cadastrado</div>}
          </div>
          <div className="uk-flex uk-flex-between uk-margin-small">
            <span>{listLimit} de {codeList.length}</span>
            {renderLoadMoreButton()}
            <span>Usados: {codeList.filter(code => code.voucherId).length}</span>
          </div>
          <div className="uk-width-1-1 uk-margin-top">
            <input type="file" id="csvfile" name="csvfile" accept=".csv" onChange={onFileSelected} />
          </div>
        </div>
        <div className="uk-modal-footer uk-text-right">
          <button className="uk-button uk-button-default uk-margin-left" type="button" onClick={onClose}>Cancelar</button>
          <button className="uk-button uk-button-primary" type="button" onClick={onSave}>Salvar</button>
        </div>
        <div className="container-modal">{modal.show ? <modal.Content /> : null}</div>
      </div>
    </Overlay>
  );
};

export default PreGeneratedModal;