import { ifError } from "assert";

export default (dispatch, state) => {

  const getInvalidFields = errors => {
    if(errors.result) {
      const errorMessages = errors.result.map(error => error.field)
      const fields = errorMessages.map(error => error.split(':')[1])
      return fields.reduce((errs, f) => {
        return {
          ...errs,
          [f]: false
        }
      }, {})
    }
    return { _server: true }
  }

  const getErrorMessage = (error, config) => {
    
    const messages = [ `${config.message}. ` ]

    
    if(error) {
      if(error.result && Array.isArray(error.result) && error.result[0].validation) {
        let list = []
        for (let index = 0; index < error.result.length; index++) {
          const e = error.result[index];
          
          if(e.validation.match(/required/gi)) {
            const name = e.field.split(":")[1]
            if(!list.includes(name)) {
              list.push(name)
            }
          }
        }
        messages.push(`Os seguintes campos são obrigatórios: ${list.join(", ")}.`)
      }
    }

    return messages.join("")
  }

  const find = ({ name, action, token, values, options, actions, success, error }) => {
    actions.entity("loader").addLoading(action, `${name}Form`);


    return actions
      .entity(name)
    [action](values, options, token)
      .then(res => {
        if (res.name != success.name) throw res;

        // Values
        Object.entries(res.result.values[0]).map(([key, value]) => {
          actions.updater(key, { value }, value);
        });

        // Triggers
        Object.entries(res.result.values[0]).map(([key, value]) => {
          if (document) {
            let element = document.getElementById(key);
            if (element) {
              element.target = { value };
              element.value = value;
              element.dispatchEvent(new Event('change', { bubbles: true }));
            }
          }
          actions.updater(key, { value }, value);
        });

        actions.entity("loader").removeLoading(action, `${name}Form`);

        success.action.notify &&
          actions
            .notification("notification")
            .add(
              res.code,
              success.action.notify,
              "success",
              success.message,
              undefined,
              process.env.notificationTimeOut
            );

        success.action.redirect && success.action.redirect();

        return res;
      })
      .catch(err => {
        actions.entity("loader").removeLoading(action, `${name}Form`);

        error.action.notify &&
          actions
            .notification("notification")
            .add(
              err.code,
              error.action.notify,
              "danger",
              getErrorMessage(err, error),
              undefined,
              process.env.notificationTimeOut
            );

        error.action.redirect && error.action.redirect();
      });
  };

  const submit = ({ name, action, token, values, actions, success, error }) => {
    actions.entity("loader").addLoading(action, `${name}Form`);
    
    return actions
      .entity(name)
    [action](values, token)
      .then(res => {

        if (res.name != success.name) throw res;

        actions.entity("loader").removeLoading(action, `${name}Form`);

        success.action.notify &&
          actions
            .notification("notification")
            .add(
              res.code,
              success.action.notify,
              "success",
              success.message,
              undefined,
              process.env.notificationTimeOut
            );

        success.action.redirect && success.action.redirect();
        return res;
      })
      .catch(err => {
        actions.entity("loader").removeLoading(action, `${name}Form`);
        
        let er = error[err.name] ? error[err.name] : error;

        let fields = getInvalidFields(err)

        if (er.action.notify) {
          actions
            .notification("notification")
            .add(
              err.code,
              er.action.notify,
              "danger",
              fields._server ? 'Houve um error no servidor, tente novamente mais tarde' : 'Os campos abaixo em destaque possuem erro de validação',
              undefined,
              process.env.notificationTimeOut
            );
        }

        er.action.redirect && er.action.redirect();
        throw fields;
      });
  };

  return {
    submit,
    find
  };
};
