
const ageRanges = [
  { min: 10, max: 25},
  { min: 25, max: 35},
  { min: 35, max: 45},
  { min: 45, max: 55},
  { min: 55, max: 65},
  { min: 65, max: undefined},
]

const generateLabels = (ranges) => {
  return ranges.map(({min, max}) => {
    if (min && max) {
      return `${min}-${max}`
    }
    return `${min}+`
  })
}

const idadeLayout = {
  labels: generateLabels(ageRanges),
  datasets: [
    {
      label: "Idade",
      backgroundColor: "#622D8F",
      borderColor: "#622D8F",
      borderWidth: 1,
      data: ageRanges.map(_ => 0)
    }
  ]
};


export {
  idadeLayout,
  ageRanges,
}