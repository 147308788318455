// @ts-ignore
import React, { Component, Fragment, useEffect } from "react";
// @ts-ignore
import { Link, Redirect, withRouter } from "react-router-dom";
import Datetime from "react-datetime";
import moment from "moment";

import { SelectWrapper } from "../../../wrappers/FormWrappers.react";

import ChartComponent from "@html/views/utils/ChartComponent.react";
import PercentOptions from "./chartsConfig/PercentOptions";
import ValueOptions from "./chartsConfig/ValueOptions";

import { idadeLayout, ageRanges } from "./chartsConfig/IdadeLayout";
import bairroLayout from "./chartsConfig/BairroLayout";
import localDeTrocaLayout from "./chartsConfig/LocalDeTrocaLayout";
import periodoLayout from "./chartsConfig/PeriodoLayout";
import diasDaSemanaLayout from "./chartsConfig/DiasDaSemanaLayout";
import Loader from "@html/views/utils/LoaderBooleanComponent.react";

import requests from "./components/RedemptionConsumerReport.requests";

import Access from "@cthulhi/comp-accs";
import Context from "@context";

const Page = props => {
  const { state: accState, action: accAction } = Access.useState();
  const { action: ctxAction } = Context.useState();

  const [voucherInfo, setVoucherInfo] = React.useState({
    used: 0,
    total: 0,
    pending: 0,
    tovalidate: 0
  });

  const [redemptionInfo, setRedemptionInfo] = React.useState({
    newConsumers: 0, 
    branchRecurrent: 0, 
    totalConsumers: 0 
  });

  const [startDate, setStartDate] = React.useState(moment().startOf("week"));
  const [endDate, setEndDate] = React.useState(moment().endOf("week"));
  

  const [businessList, setBusinessList] = React.useState([]);
  const [businessOptions, setBusinessOptions] = React.useState([]);
  const [branchesOptions, setBranchesOptions] = React.useState([]);

  const [selectedBranch, setSelectedBranch] = React.useState("");
  const [selectedBusiness, setSelectedBusiness] = React.useState("");

  const [isLoading, setIsLoading] = React.useState(false);

  const selectOptionItem = (_id, name) => {
    return { _id, name, value: _id }
  }

  const onChangeDate = setDate => date => {
    const isDate =
      typeof date.includes != "function" ? true : date.includes("Invalid date") ? true : false;

    if (!isDate) {
      setDate("");
    } else {
      setDate(moment(date, "YYYY-MM-DD"));
    }
  };

  const formatDate = date => {
    return moment(date).format("DD / MM / YYYY");
  };

  

  const getBusiness = async () => {

    const filter = { 
      filters: {}, 
      aggregates: [{
        lookup: {
          from: "branch",
          field: "branches",
          match: "_id",
          result: "branchesList"
        }
      }] 
    };

    const options = { 
      aggregates: [
        { $sort: { name: 1 }  }
      ]
    };

    const token = `Bearer ${accState.access.session.context.token}`;

    const getBusinessOptions = (res) => {

      let businessOptions = [selectOptionItem("-1", "Todos os parceiros")];

      if (res.result && res.result.values) {
        setBusinessList(res.result.values)

        businessOptions = res.result.values.map(business => selectOptionItem(business._id, business.name));
        
        if(accState.access.session.context.payload.roles.includes("admin")) {
          businessOptions.unshift(selectOptionItem("-1", "Todos os parceiros"))
        }
      }

      return businessOptions;
    }

    await ctxAction("business").find(filter, options, token)
        .then(res => {
          const businessOptionsList = getBusinessOptions(res)

          if(businessOptionsList.length == 1) {
            setSelectedBusiness(businessOptionsList[0]._id)
          } else {
            setBusinessOptions(businessOptionsList)
          }
        })
        .catch(e => console.error(e))
  }

  const getBranches = async (_id) => {
    
    const getBranchesOptions = () => {
      
      let branchOptions = [selectOptionItem("-1", "Todas as unidades")];

      let values = businessList.filter(business => business._id == _id)
        .map(business => { 
          return business.branchesList.map(branch => selectOptionItem(branch._id, branch.name))
        })

      if(values.length > 0) {
        branchOptions = [selectOptionItem("-1", "Todas as unidades"), ...values[0]]
      }
  
      return branchOptions;
    }

    if(_id == "-1") {
      setSelectedBranch("-1")
      setBranchesOptions([]);
    } else {
      setBranchesOptions(getBranchesOptions())
    }
  }

  const getVouchers = async () => {

    const getVoucherStatus = async () => {
    
      const token = `Bearer ${accState.access.session.context.token}`;
      
      let filter = {
        filters: { 
          createdAt: {
            $gte: new Date(startDate.toISOString()),
            $lte: new Date(endDate.toISOString())
          } 
        },
        aggregates: [
          {
            $group: {
              _id: "$status",
              count: { $sum: 1 }
            }
          },
          {
            $sort: { count: -1 }
          }
        ]
      };
  
      if (selectedBusiness && selectedBusiness != "-1") {
        filter.filters.businessId = selectedBusiness

        if (selectedBranch && selectedBranch != "-1") {
          filter.filters.branchId = selectedBranch
        }
      }

      const options = {};
  
      return ctxAction("voucher")
        .find(filter, options, token)
        .then(res => {
          const statusCount = {
            pending: 0,
            tovalidate: 0,
            used: 0,
            total: 0
          };
  
          if (res.result && res.result.values && res.result.values[0]) {
            const values = res.result.values;
  
            for (const status of values) {
              if (statusCount[status._id] === 0) {
                statusCount[status._id] = status.count;
                statusCount.total += status.count;
              }
            }
  
            return statusCount;
          }
  
          return statusCount;
        })
    }

    if(selectedBusiness) {
      setVoucherInfo(await getVoucherStatus())
      setIsLoading(false)
    }
  }

  
  const getReport = async () => {
    await getVouchers()
  }

  const updateBusiness = async () => {
    if(!isLoading) {
      await getBusiness()
    }
  }

  const updateBranches = async (selectedBusiness) => {
    if(selectedBusiness) {
      setIsLoading(true);
      
      await getBranches(selectedBusiness)
      await getReport();
    }
  }

  const updateFilters = async () => {
    setIsLoading(true);
    
    await getReport();
  }
 
  useEffect(() => {
    updateBusiness()
  }, [])
  
  useEffect(() => {
    updateBranches(selectedBusiness)
  }, [selectedBusiness])

  useEffect(() => {
    updateFilters()
  }, [selectedBranch, startDate, endDate])


  return (
    <Fragment>
      <h2 className="title">Perfil do consumidor</h2>
      <form>
        <div className="uk-flex uk-flex-around uk-margin-top">
          <div>
            <span className="label">Início do período</span>
            <Datetime
              className="datepicker"
              dateFormat="DD / MM / YYYY"
              timeFormat={false}
              inputProps={{
                className: "uk-input uk-margin-right",
                placeholder: "Início do período"
              }}
              value={formatDate(startDate)}
              onChange={onChangeDate(setStartDate)}
            />
          </div>
          <div>
            <span className="label">Fim do período</span>
            <Datetime
              className="datepicker"
              dateFormat="DD / MM / YYYY"
              timeFormat={false}
              inputProps={{ className: "uk-input", placeholder: "Fim do período" }}
              value={formatDate(endDate)}
              onChange={onChangeDate(setEndDate)}
            />
          </div>
        </div>
        <div className="uk-margin">
          {accState.access.session.context.payload.roles.includes("admin") ? (
            <SelectWrapper
              selectedValue={selectedBusiness}
              setSelectedValue={setSelectedBusiness}
              data={businessOptions}
              value="_id"
              content="name"
            />
          ) : null}
          {branchesOptions.length > 0 ? (
            <SelectWrapper
              selectedValue={selectedBranch}
              setSelectedValue={setSelectedBranch}
              data={branchesOptions}
              value="_id"
              content="name"
            />
          ) : null}
        </div>
      </form>
      <Loader show={isLoading} />

      <h2 className="subtitle uk-margin-medium-top">Consumo de vouchers</h2>
      <div
        className="uk-child-width-1-2@s uk-child-width-1-1@m uk-child-width-1-2@l uk-child-width-1-3@xl uk-margin-medium-top uk-margin-large-bottom uk-grid"
      >
        <div>
          <span className="label">Gerados</span>
          <div className="number-container">{voucherInfo.pending}</div>
          <span className="label label-small">{`${formatDate(startDate)} a ${formatDate(
            endDate
          )}`}</span>
        </div>
        <div>
          <span className="label">Usados</span>
          <div className="number-container">{voucherInfo.tovalidate}</div>
          <span className="label label-small">{`em ${
            startDate ? moment().diff(startDate, "days") : 0
          } dias`}</span>
        </div>
        <div>
          <span className="label">Avaliados</span>
          <div className="number-container">{voucherInfo.used}</div>
          <span className="label label-small">{`restam ${
            endDate ? endDate.diff(moment(), "days") : 0
          } dias`}</span>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(Page);
